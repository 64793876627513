import React, { Fragment } from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import { Theme } from '@pga/pga-component-library'
import Layout from '../../../layouts/base-with-sidebar-for-pages'
import { getFirstNodeFm } from '../../../utils'
import IconCards from '../../../components/IconCards'

const Button = styled.a`
  border: 1px solid ${Theme.colors.pearlGrey};
  color: ${Theme.colors.primary};
  background-color: transparent;
  padding: 7px 10px;
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  &:not(:hover) {
    color: ${Theme.colors.primary} !important;
  }
`

const IconPdfWrapper = styled.span`
  padding-right: .5rem;
  font-size: 16px;
  i {
    color: white;
    background-color: #B5100F;
  }
`

const ImageWithTextWrapper = styled.div`
  position: relative;
  .text-block {
    position: absolute;
    bottom: 0px;
    right: 0px;
    background-color: #00234b;
    padding-left: 3%;
    padding-right: 3%;
    height: 100%;
    opacity: .9;
    div {
      display: flex;
      flex-direction: column;
      padding: 10px;
      justify-content: center;
      height: 100%;
    }
    h4 {
      font-family: ${Theme.fonts.PlayfairDisplay};
      color: white;
      margin-bottom: 1rem;
      font-size: 24px;
    }
    p {
      font-family: ${Theme.fonts.Montserrat};
      color: white;
      font-size: 16px;
    }
  }
`
const Image = styled.img`
  max-width: 100%;
  height: auto;
  @media (max-width: 575.98px) {
    height: 300px;
  }
`

const ImageWithText = () => {
  return (
    <ImageWithTextWrapper>
      <Image src='/uploads/strategic-plan/MissionPurpose-img.jpg' alt='' />
      <div className='text-block'>
        <div>
          <h4>Mission</h4>
          <p>Serve the member and grow the game</p>
          <h4>Purpose</h4>
          <p>Elevate and advance the member, profession and game</p>
        </div>

      </div>
    </ImageWithTextWrapper>
  )
}

const ListBulletPrimary = styled.ul.attrs({ className: 'ul-bullets-primary' })`
  padding-inline-start: 25px;
`

const ImageCol = styled.div.attrs({ className: 'col-sm-5 col-xs-12' })`
  order: ${props => props.i % 2 === 0 ? 0 : 1};
  @media (max-width: 575.98px) {
    margin-bottom: 16px;
    order: 0;
  }
`

const StrategicPriorities = ({ data }) => {
  return data && data.map((item, i) => (
    <Fragment key={i}>
      <div className='row my-4'>
        <ImageCol i={i}>
          <img src={item.image} className='img-fluid' alt='' />
        </ImageCol>

        <div className='col-sm-7 col-xs-12'>
          <h4>{item.title}</h4>

          <p className='fw-500'>Strategic Priorities</p>

          {item.list.map((l, idx) => (
            <ListBulletPrimary key={idx}>
              <li>{l}</li>
            </ListBulletPrimary>
          ))}
        </div>
      </div>
      {data.length - 1 !== i && <hr />}
    </Fragment>

  ))
}

export const PageWrapper = (props) => {
  const { data } = props
  const frontmatter = getFirstNodeFm(data)

  return (
    <Layout {...props}>
      <div className='layout-default'>
        <div className='mb-4'>

          {frontmatter.pdf_file_path && <Button href={frontmatter.pdf_file_path} target='_blank'>
            <IconPdfWrapper><i className='fa fa-file-pdf-o' /></IconPdfWrapper>
            Download Strategic Plan PDF
          </Button>}
        </div>

        <ImageWithText />

        <h3 className='my-5 mb-4'>Four Key Stakeholders & Goals</h3>
        {frontmatter.stakeHolderGoals && <IconCards data={frontmatter.stakeHolderGoals} />}

        <h3 className='my-4'>Strategic Priorities and Key Initiatives of Four Key Stakeholders</h3>

        {frontmatter.strategicPriorities && <StrategicPriorities data={frontmatter.strategicPriorities} />}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: {
        fields: {
          slug: {
            regex: "/my-membership/membership-overview/strategic-plan/"
          }
        }
      }
    ) {
      edges {
        node {
          fileAbsolutePath
          fields {
            slug
            category
            subCategory
            article
          }
          frontmatter {
            title
            header_image
            sidebar_label
            order_number
          }
        }
      }
    }
    categoryMarkdowns: allMarkdownRemark(
      filter: {
        fields: {
          slug: {
            regex: "/my-membership/"
          }
        }
      }
    ) {
      edges {
        node {
          ...MarkdownFrontmatter
        }
      }
    }
  }
`

export default PageWrapper
