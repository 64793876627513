import React from 'react'
import PropTypes from 'prop-types'

export const IconCard = ({ item }) => (
  <div className='col-xl-3 col-lg-6 col-md-6 d-flex'>
    <div className='card shadow-none w-100'>
      <div className='card-body'>
        <img width={62} height={62} alt={item.title} src={item.icon} className='d-flex mx-auto' />
        <hr />
        <h6 className='mt-3 mb-1 fw-700'>{item.title}</h6>
        <p className='mb-0 mt-3 text-muted'>{item.paragraph}</p>
      </div>
    </div>
  </div>
)

const IconCards = ({ data }) => (
  <div className='row'>
    {data.map((item, idx) => <IconCard {... { item }} key={idx} />)}
  </div>
)

IconCards.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      paragraph: PropTypes.string,
      icon: PropTypes.string
    })
  )
}

export default IconCards
